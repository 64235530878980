import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import AddDepartmentForm from './AddDepartmentModalForm';

export const AddDepartmentModal = ({ 
    handleAddDepartment=() => {}, 
    isLoading=0, 
    isModalOpen = false,
    handleModalOpen=() => {}
  }) => {
  return (
    <>
      <Button type="primary" onClick={handleModalOpen} loading={isLoading != 0 }>
        Add Department
      </Button>
      <Modal title="Add Department" open={isModalOpen} onCancel={handleModalOpen} footer={null}>
        {<AddDepartmentForm handleAddDepartment={handleAddDepartment} isLoading={isLoading}/>}
      </Modal>
    </>
  );
};
export default AddDepartmentModal;