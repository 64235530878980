import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import AddUserModalForm from './AddUserModalForm';

export const AddUserModal = ({ 
    handleAddUser=() => {}, 
    isLoading=0, 
    isModalOpen = false,
    handleModalOpen=() => {},
    handleCheckUsernameExist = () => {}
  }) => {
  return (
    <>
      <Button type="primary" onClick={handleModalOpen} loading={isLoading != 0 }>
        Add User
      </Button>
      <Modal title="Add User" open={isModalOpen} onCancel={handleModalOpen} footer={null}>
        {<AddUserModalForm 
            handleAddUser={handleAddUser} 
            isLoading={isLoading}
            handleCheckUsernameExist={handleCheckUsernameExist}
        />}
      </Modal>
    </>
  );
};
export default AddUserModal;