import { Spin } from "antd"

export const ApplicationHocComponent = ({children, isApplicationLoading=0}) => {
    return (
    <Spin spinning={isApplicationLoading != 0}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            {children}
        </div>
    </Spin>)
    
}