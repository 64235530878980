import React, { useEffect } from 'react';
import { Checkbox, Space, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDepartmentList } from '../../../store/asyncReducers';
const columns = [
    {
        title: 'Department Name',
        dataIndex: 'departmentName'
    },
    {
        title: 'Read',
        dataIndex: 'departmentRead',
        align: 'center',
        render:(_, record) => (
            <Space size="middle">
              <Checkbox defaultChecked={record.isActive} onChange={(e) => {}} />
            </Space>
          )
    },
    {
        title: 'Write',
        dataIndex: 'departmentWrite',
        align: 'center',
        render:(_, record) => (
            <Space size="middle">
              <Checkbox defaultChecked={record.isActive} onChange={(e) => {}} />
            </Space>
          )
    },    
];

export const UserDepartmentTable = ({ tableData = [], userId=null }) => {
    const dispatch = useDispatch();
    const isDepartmentLoading = useSelector(state => state.application.department.isDepartmentLoading);
    useEffect(() => {
        dispatch(fetchUserDepartmentList({ userId }))
    },[userId])
    return (
            <>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    loading={isDepartmentLoading != 0}
                />
            </>
    );
};