import { Button, Space, Switch, Table } from "antd"
import { AddUserModal } from "./AddUserModal"
import { addNewUser, checkIfUsernameExist, fetchUsersList, updateUserActiveStatus } from "../../store/asyncReducers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { UserDepartment } from "./userDepartment";
import Search from "antd/es/transfer/search";

export const UserComponent = () => {

    const dispatch = useDispatch();
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ isDepartmentModalOpen, setIsDepartmentModalOpen ] = useState(false);
    const [ searchTerm, setSearchTerm ] = useState("");
    const isUsersLoading = useSelector(state => state.application.users.isUsersLoading);
    const usersListData = useSelector(state => state.application.users.usersList);
    const userDepartmentList = useSelector(state => state.application.department.userDepartmentList);

    const handleAddUser = async (formValues) => {
        await dispatch(addNewUser(formValues)).unwrap()
        setIsModalOpen(!isModalOpen)
    }

    const handleCheckUsernameExist = async (username) => {
        const response = await dispatch(checkIfUsernameExist(username)).unwrap()
        return response
    }

    const handleModalOpen = () => {
        setIsModalOpen(!isModalOpen)
    }

    const handleDepartmentModalOpen = () => {
        setIsDepartmentModalOpen(!isDepartmentModalOpen)
    }

    const handleUserActive = (userId, activeStatus) => {
        console.log(userId)
        dispatch(updateUserActiveStatus({ userId: userId.id, userStatus: activeStatus}))
    }

    const handleSetSearchTerm = (e) => {
        setSearchTerm(e.target.value)
    }

    const updatedColumns = !Array.isArray(usersListData?.columnNames)? [] : usersListData?.columnNames.map((datum, index) => {
        if(index === usersListData?.columnNames?.length-1){
            return {
                ...datum, 
                render:(_, record) => (
                    <Space size="middle">
                      <Switch defaultChecked={record.isActive} onChange={(e) => {handleUserActive(record, e)}} />
                    </Space>
                  )
            }
        }else if(index === usersListData?.columnNames?.length-2){
            return {
                ...datum, 
                render:(_, record) => (
                    <Space size="middle">
                      <UserDepartment 
                        handleModalOpen={handleDepartmentModalOpen} 
                        isModalOpen={isDepartmentModalOpen} 
                        departmentList={userDepartmentList} 
                        userId={record.id} 
                        key={record.id}
                    />
                    </Space>
                  )
            }
        }else{
            return datum
        }
    })

    useEffect(() => {
        dispatch(fetchUsersList(searchTerm))
    },[searchTerm])

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div>
                    <Search 
                        placeholder="Search by firstname, lastname or username" 
                        enterButton="Search" 
                        size="large" 
                        onChange={handleSetSearchTerm} 
                        loading={isUsersLoading != 0}
                    />
                </div>
                <div>
                    <AddUserModal 
                        handleAddUser={handleAddUser} 
                        handleModalOpen={handleModalOpen} 
                        isModalOpen={isModalOpen}
                        handleCheckUsernameExist={handleCheckUsernameExist}
                    />
                </div>
                
            </div>
            <div style={{ paddingTop: '10px'}}>
                <Table columns={updatedColumns} dataSource={usersListData?.tableData}/>
            </div>
        </div>
    )
}