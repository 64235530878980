import { message } from "antd";
import axios from "axios";
import { Logger } from "./logger";

const ifStatusOK = (response) => response.status === 200;

class httpService {
  
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('railwayFileApplication')}`,
        "Access-Control-Allow-Origin": "*",
      },
    });

    // attach interceptors to axios instance to modify the success and error response
    this.instance.interceptors.response.use(
      (response) => {
        Logger.log('HTTP SERVICE', response)
        return Promise.resolve(response);
      },
      (error) => {
        Logger.log("HTTP ERROR", error, error.response);
        // if(error.code == 504)
        // return Promise.reject(error)

        return Promise.reject(error.response);
      }
    );
    // this.instance.defaults.timeout = 5000;
  }

  /*
        GET REQUEST
        @param {string} url
        @param {object} payload
        @param {object} headers
        @return {promise} 
    */
  GET(url, payload = null, headers = {}) {
    return new Promise((resolve, reject) => {
      this.instance
        .get(url, {
          params: payload,
          headers: headers,
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          message.error(error)
          reject(error)
        });
    });
  }

  /*
       POST REQUEST
       @param {string} url
       @param {object} payload
       @param {object} headers
       @return {promise} 
   */
  POST(url, payload = null, headers = {}) {
    return new Promise((resolve, reject) => {
      this.instance
        .post(url, payload ? payload : null, {
          headers: headers,
        })
        .then((response) => {
          if (ifStatusOK(response)) {
            if (!response.data.error && response.data.message && response.data.message !== '')
              message.success(response.data.message)
            else if (response.data.error)
              message.error(response.data.message)
            resolve(response.data)
          }
          else reject(response);
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  /*
       DELETE REQUEST
       @param {string} url
       @param {object} payload
       @param {object} headers
       @return {promise} 
   */
  DELETE(url, payload = null, headers = {}) {
    return new Promise((resolve, reject) => {
      this.instance
        .delete(url, {
          headers: headers,
          data: payload,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new httpService();