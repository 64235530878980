import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "../../utils/httpService";
import { serverApis } from "../../utils/serverApi";

export const loginUserService = createAsyncThunk(serverApis.LOGIN_USER_SERVICE, async (data, { rejectWithValue }) => {
    try {
        const resp = await httpService.POST(serverApis.LOGIN_USER_SERVICE, data)
        return resp
    }catch(err){
        return rejectWithValue(err)
    }
})

export const fetchDepartmentList = createAsyncThunk(serverApis.FETCH_DEPARTMENT_LIST, async (_ , {rejectWithValue}) => {
    try{
        const resp = await httpService.GET(serverApis.FETCH_DEPARTMENT_LIST)
        return resp
    }catch(err){
        return rejectWithValue(err)
    }
})

export const addNewDepartment = createAsyncThunk(serverApis.ADD_NEW_DEPARTMENT, async (data, {rejectWithValue, dispatch}) => {
    try{
        const resp = await httpService.POST(serverApis.ADD_NEW_DEPARTMENT, data)
        dispatch(fetchDepartmentList())
        return resp
    }catch(err){
        return rejectWithValue(err)
    }
    
})

export const addNewUser = createAsyncThunk(serverApis.ADD_NEW_USER, async (data, {rejectWithValue, dispatch}) => {
    try{
        const resp = await httpService.POST(serverApis.ADD_NEW_USER, data)
        dispatch(fetchUsersList(''))
        return resp
    }catch(err){
        return rejectWithValue(err)
    }
})

export const fetchUsersList = createAsyncThunk(serverApis.FETCH_USERS_LIST, async (data , {rejectWithValue}) => {
    try{
        const resp = await httpService.POST(serverApis.FETCH_USERS_LIST, { searchTerm: data})
        return resp
    }catch(err){
        return rejectWithValue(err)
    }
})

export const checkIfUsernameExist = createAsyncThunk(serverApis.CHECK_USERNAME_EXIST, async (username , {rejectWithValue}) => {
    try{
        const resp = await httpService.GET(`${serverApis.CHECK_USERNAME_EXIST}/${username}`)
        return resp
    }catch(err){
        return rejectWithValue(err)
    }
})

export const updateUserActiveStatus = createAsyncThunk(serverApis.UPDATE_USER_STATUS, async (data , {rejectWithValue, dispatch}) => {
    try{
        const resp = await httpService.POST(serverApis.UPDATE_USER_STATUS, data)
        dispatch(fetchUsersList(''))
        return resp
    }catch(err){
        return rejectWithValue(err)
    }
})

export const fetchUserDepartmentList = createAsyncThunk(serverApis.FETCH_USER_DEPARTMENT, async (data , {rejectWithValue, dispatch}) => {
    try{
        const resp = await httpService.POST(serverApis.FETCH_USER_DEPARTMENT, data)
        return resp
    }catch(err){
        return rejectWithValue(err)
    }
})