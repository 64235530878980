import { Navigate, useLocation } from 'react-router-dom'
import { checkIfUserIsLoggedIn } from '../utils/checkIfUserIsLoggedIn'

const PrivateRoute = ({ children }) => {

  // Add your own authentication on the below line.
  const isLoggedIn = checkIfUserIsLoggedIn()
  const location = useLocation()

  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate
      replace={true}
      to="/login"
      state={{ from: `${location.pathname}${location.search}` }}
    />
  )
}

export default PrivateRoute