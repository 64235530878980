import { Route, Routes, useNavigate } from "react-router-dom";
import { HeaderComponent } from "./components/header";
import { LoginComponent } from "./components/login";
import { ApplicationHocComponent } from "./components/hoc/mainHoc";
import { AdminComponent } from "./components/admin";
import PrivateRoute from "./routes/PrivateRoute";


function App() {
  return (
    <div>
      <HeaderComponent/>
        <Routes>
            <Route path='/login' exact element={ <ApplicationHocComponent children={<LoginComponent />} />}/>
            <Route path='/' exact/>
            <Route path='/admin' exact element={ <PrivateRoute><AdminComponent /></PrivateRoute> }/>
            <Route path='*' exact element={ <ApplicationHocComponent children={<LoginComponent />} />}/>
        </Routes>
    </div>
  );
}

export default App;
