import { Tabs } from 'antd';
import React from 'react';
import { DepartmentComponent } from './department';
import { UserComponent } from './user';

export const AdminComponent = () => {

    const items = [
        {
            key: 'department',
            label: 'Department',
            children: <DepartmentComponent />
        },
        {
            key: 'user',
            label: 'Users',
            children: <UserComponent />
        },
    ]

    return (
        <div style={{
            paddingTop: '10px'
        }}>
        <Tabs
            tabPosition={'left'}
            items={items}
        />
        </div>
    );
};
export default AdminComponent;