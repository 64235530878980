import { Button, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
const { Option } = Select

export const AddUserForm = ({ 
    handleAddUser = () => {}, 
    isLoading=false,
    handleCheckUsernameExist=() => {}
  }) => {
    const [ error, setError ] = useState(false)
    const [ message, setMessage ] = useState(null)
    const [form] = Form.useForm();

    const handleUsername = async (e) => {
      if(e.target.value){
        const response = await handleCheckUsernameExist(e.target.value)
        if(response.data){
          setError(true)
          setMessage(response.message)
        }else{
          setError(false)
          setMessage(null)
        }
      }
    }

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={handleAddUser}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        label="First Name:"
        name="firstName"
        rules={[
          {
            required: true,
            message: 'First Name is required.'
          },
        ]}
      >
        <Input placeholder='Enter First Name..'/>
      </Form.Item>
      <Form.Item
        label="Last Name :"
        name="lastName"
        rules={[
          {
            required: true,
            message: 'Last Name is required.'
          },
        ]}
      >
        <Input placeholder='Enter Department Name..'/>
      </Form.Item>
      <Form.Item
        label="Username  :"
        name="userName"
        rules={[
          {
            required: true,
            message: 'username is required.'
          },
        ]}
        validateStatus={error ? 'error' : 'success'}
        help={message}
        hasFeedback
      >
        <Input placeholder='Enter username..' onChange={handleUsername}/>
      </Form.Item>
      <Form.Item
        label="Password  :"
        name="userPassword"
        rules={[
          {
            required: true,
            message: 'Password is required.'
          },
        ]}
      >
        <Input.Password placeholder='Enter password..' type='password'/>
      </Form.Item>
      <Form.Item
        name="userType"
        label="User Role :"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select User Type.."
          allowClear
        >
          <Option value={1}>Admin</Option>
          <Option value={2}>Normal User</Option>
        </Select>
      </Form.Item>
      
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width:'100%'}} loading={isLoading} disabled={!!error}>
          Add User
        </Button>
      </Form.Item>
    </Form>
  );
};
export default AddUserForm;