import { Button, Table } from "antd"
import AddDepartmentModal from "./AddDepartmentModal"
import { useDispatch, useSelector } from "react-redux"
import { addNewDepartment, fetchDepartmentList } from "../../store/asyncReducers";
import { useEffect, useState } from "react";

export const DepartmentComponent = () => {
    const dispatch = useDispatch();
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const isDepartmentLoading = useSelector(state => state.application.department.isDepartmentLoading)
    const departmentListData = useSelector(state => state.application.department.departmentList)

    const handleAddDepartment = (formValues) => {
        dispatch(addNewDepartment({ departmentName: formValues['departmentName']}))
        setIsModalOpen(!isModalOpen)
    }

    const handleModalOpen = () => {
        setIsModalOpen(!isModalOpen)
    }

    useEffect(() => {
        dispatch(fetchDepartmentList())
    },[])

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <AddDepartmentModal 
                    handleAddDepartment={handleAddDepartment} 
                    isLoading= {isDepartmentLoading} 
                    handleModalOpen={handleModalOpen}
                    isModalOpen={isModalOpen}
                />
            </div>
            <div style={{ paddingTop: '10px'}}>
                <Table 
                    isLoading={isDepartmentLoading} 
                    columns={ departmentListData?.columnNames}
                    dataSource={ departmentListData?.tableData}
                />
            </div>
        </div>
    )
}