export const serverApis = {
    // USER API's
    LOGIN_USER_SERVICE: 'user/login',

    //Admin API's
    FETCH_DEPARTMENT_LIST: 'department/',
    ADD_NEW_DEPARTMENT: 'department/add',
    FETCH_USER_DEPARTMENT: 'department/user-department',

    // USER API's
    ADD_NEW_USER: 'user/add',
    FETCH_USERS_LIST: 'user/',
    CHECK_USERNAME_EXIST: 'user/username',
    UPDATE_USER_STATUS: 'user/active',
}