import { Button, Modal, Table } from "antd"
import { UserDepartmentTable } from "./UserDepartmentTable"

export const UserDepartment = ({ handleModalOpen = () => {}, isLoading = 0, isModalOpen = false, departmentList = {}, userId=null}) => {
    const { tableData } = departmentList
    return (
        <>
            <Button type="primary" onClick={handleModalOpen} loading={isLoading != 0}>
                User Department
            </Button>
            <Modal title="User Department" open={isModalOpen} onCancel={handleModalOpen} footer={null}>
                <UserDepartmentTable tableData ={tableData} isLoading={isLoading} userId={userId}/>
            </Modal>
        </>
    )
}