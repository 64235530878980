import { createSlice } from '@reduxjs/toolkit'
import { 
    loginUserService, addNewDepartment, fetchDepartmentList,
    addNewUser, fetchUsersList, checkIfUsernameExist, fetchUserDepartmentList
} from './asyncReducers'
import { message } from 'antd'

const initialState = {
    userDetails: {
        userId: null,
        userName: null,
        userRole:null,
        roleNumber:null,
    },
    department: {
        departmentList: {
            columnNames: [],
            tableData: []
        },
        isDepartmentLoading: 0,
        userDepartmentList: []
    },
    users: {
        usersList: {
            columnNames: [],
            tableData: []
        },
        isUsersLoading: 0
    },
    is_authenticated: false,
    isApplicationLoading: 0
}

const { actions, reducer } = createSlice({
    name: 'mainAppStore',
    initialState,
    reducers: {
        setIsFullScreen(state, action) {
            state.isFullScreen = action.payload
        },
        setCurrentScreenTitle(state, action) {
            state.currentScreenTitle = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(loginUserService.pending, (state) => {
            state.isApplicationLoading += 1
        }).addCase(loginUserService.fulfilled, (state, {payload}) => {
            state.isApplicationLoading -= 1
            state.userDetails = payload.data.userDetails
            state.is_authenticated = true
            localStorage.setItem("railwayFileApplication", payload.data.access_token)
            // message.success(payload.data.message)
        }).addCase(loginUserService.rejected, (state, {payload}) => {
            state.isApplicationLoading -= 1
            state.is_authenticated = false
            message.error(payload.data.message)
        }).addCase(fetchDepartmentList.pending, (state) => {
            state.department.isDepartmentLoading += 1
        }).addCase(fetchDepartmentList.fulfilled, (state, {payload}) => {
            state.department.isDepartmentLoading -= 1
            state.department.departmentList = payload.data
        }).addCase(fetchDepartmentList.rejected, (state, {payload}) => {
            state.department.isDepartmentLoading -= 1
            message.error(payload.error)
        }).addCase(addNewDepartment.pending, (state) => {
            state.department.isDepartmentLoading += 1
        }).addCase(addNewDepartment.fulfilled, (state, _) => {
            state.department.isDepartmentLoading -= 1
        }).addCase(addNewDepartment.rejected, (state, action) => {
            state.department.isDepartmentLoading -= 1
            message.error(action.payload.data.message)
        }).addCase(addNewUser.pending, (state) => {
            state.users.isUsersLoading += 1
        }).addCase(addNewUser.fulfilled, (state, _) => {
            state.users.isUsersLoading -= 1
        }).addCase(addNewUser.rejected, (state, action) => {
            state.users.isUsersLoading -= 1
            message.error(action.payload.data.message)
        }).addCase(fetchUsersList.pending, (state) => {
            state.users.isUsersLoading += 1
        }).addCase(fetchUsersList.fulfilled, (state, {payload}) => {
            state.users.isUsersLoading -= 1
            state.users.usersList = payload.data
        }).addCase(fetchUsersList.rejected, (state, action) => {
            state.users.isUsersLoading -= 1
            message.error(action.payload.data.message)
        }).addCase(checkIfUsernameExist.pending, (state) => {
            state.users.isUsersLoading += 1
        }).addCase(checkIfUsernameExist.fulfilled, (state) => {
            state.users.isUsersLoading -= 1
        }).addCase(checkIfUsernameExist.rejected, (state, action) => {
            state.users.isUsersLoading -= 1
            message.error(action.payload.data.message)
        }).addCase(fetchUserDepartmentList.pending, (state) => {
            state.users.isUsersLoading += 1
        }).addCase(fetchUserDepartmentList.fulfilled, (state) => {
            state.users.isUsersLoading -= 1
        }).addCase(fetchUserDepartmentList.rejected, (state, action) => {
            state.users.isUsersLoading -= 1
            message.error(action.payload.data.message)
        })
    }

})

const userReducer = reducer;

export default userReducer;