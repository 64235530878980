import { Button, Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUserService } from '../store/asyncReducers';


export const LoginComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isApplicationLoading = useSelector(state => state.application.isApplicationLoading)
    const is_authenticated = useSelector(state => state.application.is_authenticated)

    const handleUserLogin = (values) => {
        dispatch(loginUserService(values)).then(() => {
            navigate('/')    
        })
    }

return (
    <Spin spinning={isApplicationLoading != 0}>
        <div style={{ paddingTop: '100px', paddingRight: '100px' }}>
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={(values) => handleUserLogin(values)}
                autoComplete="off"
            >
                <Form.Item
                    label="Username"
                    name="userName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="userPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    </Spin>
);
}