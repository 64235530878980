import { Button, Form, Input } from 'antd';
import React from 'react';

export const AddDepartmentForm = ({ handleAddDepartment = () => {}, isLoading=false}) => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={handleAddDepartment}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        name="departmentName"
        rules={[
          {
            required: true,
            message: 'Department name is required.'
          },
        ]}
      >
        <Input placeholder='Enter Department Name..'/>
      </Form.Item>
      
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width:'100%'}} loading={isLoading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default AddDepartmentForm;