import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { LoginComponent } from "../login";
import { ApplicationRoute } from "../../utils/applicationConstant";
import ApplicationLogo from '../../assets/images/logo.png'
import { useSelector } from "react-redux";



export const HeaderComponent = () => {

    const userDetails = useSelector(state => state.application.userDetails)

    const items = [
        {
            label: <NavLink to={`${ApplicationRoute}/admin`} > Admin </NavLink >,
            key: "Admin",
        },
        {
            label: <NavLink to={`${ApplicationRoute}/logout`} > Logout </NavLink >,
            key: "Logout",
        },
    ]

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '2px solid blue', borderTop: '2px solid blue'}}>
            <div style={{ width: '90%' , paddingTop: '5px'}}>
                <img src={ApplicationLogo} width={'40px'}></img>
            </div>
            <div>
                <Menu
                    mode="horizontal"
                    theme="light"
                    items={items}
                    defaultSelectedKeys={['login']}
                    className="drawerHeaderMenu"
                />
            </div>
        </div>
        
    )
}